import currencies from '@packages/data/currencies.json'
import { Button, IconButton } from '@packages/sk8/button'
import { Card } from '@packages/sk8/card'
import { HelperText, InputField, NumberInput, PlusMinusToggle } from '@packages/sk8/input'
import { useNavigationBlockingModal } from '@packages/sk8/modal'
import { Table } from '@packages/sk8/table'
import { Tag } from '@packages/sk8/tag'
import { ToastType, useToast } from '@packages/sk8/toast'
import { Tooltip } from '@packages/sk8/tooltip'
import { Currency } from '@packages/types'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { FieldArray, FormikContext, FormikErrors, useFormik } from 'formik'
import { times } from 'lodash'
import React from 'react'
import { useHistory, useParams } from 'react-router'
import * as yup from 'yup'

import Page from 'cms/layout/page/Page'
import SettingsHeader from 'cms/layout/SettingsHeader'
import SettingsSideMenu from 'cms/layout/SettingsSideMenu'
import Switch from 'common/components/Switch'
import UnsavedChangesModal from 'common/components/UnsavedChangesModal'
import useGoBack from 'common/hooks/useGoBack'
import QuestionIcon from 'icons/bold/01-Interface Essential/14-Alerts/information-circle.svg'
import LeftArrow from 'icons/bold/52-Arrows-Diagrams/01-Arrows/arrow-left-1.svg'

import { OnlineStoreCurrenciesFormValues } from '../types/form'
import useOnlineStoreService from './../hooks/useOnlineStoreService'
import ShopifyCartPriceAdjustmentSync from './shopify/ShopifyCartFunctionPriceAdjustment'

const roundingSchema = yup
  .number()
  .min(0, 'Must be 0 or more')
  .required('Please enter a valid rounding value')
  .noWhitespace()

const priceAdjustmentSchema = yup
  .number()
  .min(-99, 'Must be -99% or more')
  .max(999, 'Must be 999% or less')
  .required('Please enter a valid price adjustment')
  .noWhitespace()

const onlineStoreSchema = yup.object().shape({
  applyRounding: yup.boolean(),
  currencies: yup.array().when('applyRounding', {
    is: true,
    then: () =>
      yup.array().of(yup.object().shape({ rounding: roundingSchema, priceAdjustment: priceAdjustmentSchema })),
    otherwise: () => yup.array().of(yup.object().shape({ priceAdjustment: priceAdjustmentSchema })),
  }),
})

const OnlineStoreCurrencies = () => {
  const history = useHistory()
  const { openToast, openGenericErrorToast } = useToast()
  const queryClient = useQueryClient()
  const goBack = useGoBack()
  const { id: onlineStoreId } = useParams<{ id: string }>()

  const onlineStoreService = useOnlineStoreService()

  const { data: onlineStore, isLoading } = useQuery(
    onlineStoreService.fetch.queryKeys,
    () => onlineStoreService.fetch(onlineStoreId),
    {
      select: onlineStore => {
        const currenciesWithDefaultValues = (onlineStore.currencies || []).map(currency =>
          currency.code === onlineStore.currency ? { ...currency, rounding: 1, priceAdjustment: 0 } : currency
        )
        return { ...onlineStore, currencies: currenciesWithDefaultValues }
      },
    }
  )

  const { mutate: onSubmit } = useMutation(
    (values: OnlineStoreCurrenciesFormValues) => {
      const correctedValues = {
        ...values,
        currencies: values.currencies.map(currency => {
          if (values.applyRounding) return currency

          const currentRounding = onlineStore!.currencies?.find(({ code }) => code === currency.code)?.rounding
          return { ...currency, rounding: currentRounding }
        }),
      }

      return onlineStoreService.update(onlineStore!.id, correctedValues)
    },
    {
      onSuccess: () => {
        openToast('Currencies were successfully saved!', ToastType.success)
        queryClient.invalidateQueries(onlineStoreService.fetch.queryKeys)
      },
      onError: () => openGenericErrorToast('Currencies have not been saved.'),
    }
  )

  const formik = useFormik<OnlineStoreCurrenciesFormValues>({
    initialValues: { applyRounding: !!onlineStore?.applyRounding, currencies: onlineStore?.currencies || [] },
    validationSchema: onlineStoreSchema,
    enableReinitialize: true,
    validateOnChange: true,
    validateOnBlur: true,
    onSubmit: (values, { setSubmitting }) => onSubmit(values, { onSettled: () => setSubmitting(false) }),
  })

  const unsavedChangesModal = useNavigationBlockingModal(history, [formik.dirty])
  const defaultCurrency = onlineStore?.currencies?.find(currency => currency.code === onlineStore.currency)

  return (
    <main>
      <SettingsHeader />
      <SettingsSideMenu />

      <Page>
        <FormikContext.Provider value={formik}>
          <Page.Header>
            <div className="flex items-center">
              <IconButton role="button" Icon={LeftArrow} onClick={goBack} className="mr-2" aria-label="Go back" />
              <h1>Multi-currency</h1>
            </div>
          </Page.Header>

          <ShopifyCartPriceAdjustmentSync onlineStoreId={onlineStoreId} />

          <Page.Section>
            <Page.Aside
              title="Currency list"
              description="Conversion rate will be pulled automatically from your shopify store. Shopify API does not expose the rounding or price adjustments. Please make sure those values match the ones in your store. "
            />
          </Page.Section>

          <Page.Section className="relative">
            <Card className="w-full overflow-visible animate-form-show mb-8">
              <div className="flex flex-1 flex-col grow py-6 relative">
                <div className="flex justify-end space-x-3 px-6">
                  <span>Roundings</span>
                  <Tooltip content="Rounding enables you to round up all of your product price conversions.">
                    <QuestionIcon width={16} height={16} />
                  </Tooltip>
                  <Switch
                    checked={!!formik.values.applyRounding}
                    onChange={() => formik.setFieldValue('applyRounding', !formik.values.applyRounding)}
                  />
                </div>

                <Table>
                  <Table.Header>
                    <Table.HeaderRow>
                      <Table.HeaderCell className="w-1/3 pl-6">Currency</Table.HeaderCell>
                      <Table.HeaderCell className="w-1/3 pr-6">Rounding</Table.HeaderCell>
                      <Table.HeaderCell className="w-1/3 pl-6 pr-6">Price adjustment</Table.HeaderCell>
                    </Table.HeaderRow>
                  </Table.Header>

                  <Table.Body>
                    {isLoading &&
                      times(5, index => (
                        <Table.Row key={index}>
                          <Table.Cell>
                            <div className="mx-4 animate-pulse h-8 bg-neutral-75 rounded" />
                          </Table.Cell>
                          <Table.Cell>
                            <div className=" animate-pulse h-8 bg-neutral-75 rounded" />
                          </Table.Cell>
                          <Table.Cell>
                            <div className="mx-4 animate-pulse h-8 bg-neutral-75 rounded" />
                          </Table.Cell>
                        </Table.Row>
                      ))}

                    {defaultCurrency && (
                      <Table.Row className="h-12">
                        <Table.Cell className="space-x-6 pl-6">
                          <span>{currencies[defaultCurrency.code as keyof typeof currencies].symbol}</span>
                          <span>{currencies[defaultCurrency.code as keyof typeof currencies].name}</span>
                          <Tag className="bg-success-light text-neutral-800">Default</Tag>
                        </Table.Cell>
                        <Table.Cell />
                        <Table.Cell />
                      </Table.Row>
                    )}

                    {onlineStore?.currencies?.map((currency, index) => {
                      const isDefaultCurrency = currency.code === onlineStore.currency
                      if (isDefaultCurrency) return null

                      const formikTouchedCurrencies = formik.touched.currencies?.[index] || {}
                      const formikErrorCurrencies = (formik.errors.currencies?.[index] as FormikErrors<Currency>) || {}

                      return (
                        <FieldArray key={currency.code} name="currencies">
                          {() => {
                            return (
                              <Table.Row>
                                <Table.Cell className="space-x-6 pl-6">
                                  <span>{currencies[currency.code as keyof typeof currencies].symbol}</span>
                                  <span>{currencies[currency.code as keyof typeof currencies].name}</span>
                                </Table.Cell>

                                <Table.Cell className="pr-6">
                                  <InputField>
                                    <NumberInput
                                      id={`currencies.${index}.rounding`}
                                      name={`currencies.${index}.rounding`}
                                      min={0}
                                      defaultValue={0}
                                      autoComplete="true"
                                      value={formik.values.currencies[index]?.rounding}
                                      onValueChange={value =>
                                        formik.setFieldValue(`currencies.${index}.rounding`, value)
                                      }
                                      onBlur={formik.handleBlur}
                                      disabled={!formik.values.applyRounding}
                                      hasError={formikTouchedCurrencies.rounding && !!formikErrorCurrencies.rounding}
                                    />

                                    {formikTouchedCurrencies.rounding && !!formikErrorCurrencies.rounding && (
                                      <HelperText hasError>{formikErrorCurrencies?.rounding}</HelperText>
                                    )}
                                  </InputField>
                                </Table.Cell>

                                <Table.Cell className="pl-6 pr-6">
                                  <InputField>
                                    <div className="flex items-center space-x-2">
                                      <PlusMinusToggle
                                        small
                                        onValueChange={value =>
                                          formik.setFieldValue(`currencies.${index}.priceAdjustment`, value)
                                        }
                                        value={formik.values.currencies[index]?.priceAdjustment}
                                        disabled={!formik.values.applyRounding}
                                      />
                                      <NumberInput
                                        suffix="%"
                                        id={`currencies.${index}.priceAdjustment`}
                                        name={`currencies.${index}.priceAdjustment`}
                                        min={-99}
                                        max={999}
                                        disabled={!formik.values.applyRounding}
                                        value={formik.values.currencies[index]?.priceAdjustment}
                                        onValueChange={value =>
                                          formik.setFieldValue(`currencies.${index}.priceAdjustment`, value)
                                        }
                                        onBlur={formik.handleBlur}
                                        hasError={
                                          formikTouchedCurrencies.priceAdjustment &&
                                          !!formikErrorCurrencies.priceAdjustment
                                        }
                                      />
                                    </div>

                                    {formikTouchedCurrencies.priceAdjustment &&
                                      formikErrorCurrencies.priceAdjustment && (
                                        <HelperText hasError>{formikErrorCurrencies.priceAdjustment}</HelperText>
                                      )}
                                  </InputField>
                                </Table.Cell>
                              </Table.Row>
                            )
                          }}
                        </FieldArray>
                      )
                    })}
                  </Table.Body>
                </Table>

                <Card.StickyFooter>
                  <Button
                    type="reset"
                    onClick={() => formik.resetForm()}
                    disabled={!onlineStore || !formik.dirty || formik.isSubmitting}
                  >
                    Discard
                  </Button>
                  <Button
                    className="ml-2"
                    variant="primary"
                    isLoading={formik.isSubmitting}
                    disabled={!onlineStore || !formik.isValid || formik.isSubmitting || !formik.dirty}
                    onClick={formik.submitForm}
                  >
                    Save
                  </Button>
                </Card.StickyFooter>
              </div>
            </Card>
          </Page.Section>
        </FormikContext.Provider>
      </Page>

      {unsavedChangesModal.isVisible && (
        <UnsavedChangesModal
          onLeaveClick={() => {
            unsavedChangesModal.close()
            unsavedChangesModal.forceNavigate()
          }}
          onStayClick={unsavedChangesModal.close}
          {...unsavedChangesModal.modalProps}
        />
      )}
    </main>
  )
}

export default OnlineStoreCurrencies
